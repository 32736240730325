import { render, staticRenderFns } from "./MemberShopPointExchangeOrderTable.vue?vue&type=template&id=9e4a7068&scoped=true"
import script from "./MemberShopPointExchangeOrderTable.vue?vue&type=script&lang=js"
export * from "./MemberShopPointExchangeOrderTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e4a7068",
  null
  
)

export default component.exports