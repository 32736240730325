<template>
  <div class="member-shop-order-table">
    <BaseTable :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="訂單編號" prop="code" align="center" />
      <BaseElTableColumn label="訂單日期" width="120" prop="createdAt" align="center" />
      <BaseElTableColumn label="姓名" prop="name" align="center" />
      <BaseElTableColumn label="商品名稱" prop="productName" align="center" />
      <BaseElTableColumn label="商品數量" prop="productQuantity" align="center" />
      <BaseElTableColumn label="訂單狀態" prop="status" align="center">
        <template slot-scope="scope">
          <Tag :type="scope.row.status.tagType">{{ scope.row.status.label }}</Tag>
        </template>
      </BaseElTableColumn>
      <CustomFlowTableColumns
        :customFlowConfig="customFlowConfig"
        scope="pointExchange"
        noFilter
      />
      <BaseElTableColumn label="點數名稱" prop="pointName" align="center" width="140" />
      <BaseElTableColumn label="支付資訊" prop="displayPrice" align="center" width="140" />
      <BaseElTableColumn label="付款方式" prop="paymentType" align="center" />
      <BaseElTableColumn label="付款狀態" prop="paymentStatus" align="center">
        <template slot-scope="scope">
          <Tag :type="scope.row.paymentStatus.tagType">{{ scope.row.paymentStatus.label }}</Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <BaseElButton class="text-primary-100 underline" type="text" @click="onRowView(scope.row)">檢視</BaseElButton>
        </template>
      </BaseElTableColumn>
    </BaseTable>
  </div>
</template>

<script>
import Tag from '@/components/Tag/Tag.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router/composables'
import { formatDate } from '@/utils/date'
import { get, find } from 'lodash'
import CustomFlowTableColumns from '@/components/CustomFlowTableColumns.vue'
import { orderStatusConfig, orderPaymentStatusConfig, orderPaymentTypeConfig } from '@/config/memberShop'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'MemberShopPointExchangeOrderTable',
  components: { Tag, EmptyBlock, CustomFlowTableColumns },
  props: {
    tableData: { type: Array, default: () => [] },
    customFlowConfig: { type: Array, default: () => [] },
  },
  setup (props) {
    const router = useRouter()
    const { shopPointList } = useShop()
    const displayData = computed(() => {
      return props.tableData.map(i => {
        const orderStatus = get(orderStatusConfig, i.status)
        const paymentStatus = get(orderPaymentStatusConfig, get(i, 'MemberStoreOrderPayment.status'))
        const paymentType = get(orderPaymentTypeConfig, get(i, 'MemberStoreOrderPayment.paymentType'))

        const externalPointAmount = i.externalPointAmount
        const point = externalPointAmount || i.pointExchangeAmount
        const price = i.paidAmount
        let displayPrice = '-'
        if (!price) displayPrice = `${point} 點${externalPointAmount ? ' (第三方)' : ''}`
        if (price && point) displayPrice = `${point} 點 + $ ${price}`

        return {
          code: i.code,
          id: i.id,
          CustomFlowRecords: i.CustomFlowRecords,
          createdAt: formatDate(i.createdAt),
          name: get(i, 'Member.UserInfo.name'),
          productName: get(i, 'MemberStoreOrderProducts[0].name') || '-',
          productQuantity: get(i, 'MemberStoreOrderProducts[0].quantity') || '-',
          status: orderStatus,
          displayPrice,
          paymentType: paymentType.label,
          paymentStatus,
          pointName: get(find(shopPointList.value, { key: i.shopPointKey }), 'name', '-'),
        }
      })
    })

    const onRowView = (row) => {
      router.push({ name: 'MemberShopPointExchangeRecordDetail', params: { id: row.id } })
    }

    return {
      displayData,
      onRowView,
    }
  },
})
</script>

<style scoped lang="postcss">

</style>
